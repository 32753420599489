.wadahImageEdit {
	/* display: flex; */
	/* height: 600px; */
	/* width: 380px; */
	background-color: #c7dffa;
	margin: auto;
	border: 1px solid #c7dffa;
	box-shadow: 2px 2px 5px 5px rgb(48, 48, 54);
	border-radius: 10px;
	padding: 5px;
}

.imageEdit {
	border-radius: 10px;
	border: 2px solid rgb(174, 190, 196);
}

.btnImageEdit {
	border-radius: 6px;
	height: 25px;
	width: 60px;
	font-size: 15px;
	margin: auto;
}

.btnImageEdit:hover {
	cursor: pointer;
	background-color: cornflowerblue;
}
