@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.Li {
		@apply flex underline mr-1 hover:text-genkiWhite;
	}

	.Button {
		@apply bg-gradient-to-t from-genkiBlue-light to-genkiBlue  hover:from-genkiBlue hover:to-genkiBlue-light
		rounded-xl p-1 
		border-solid border-1 border-genkiGray-light  focus:from-yellow-300 focus:to-yellow-600 
		hover:border-genkiBlue-dark
		cursor-pointer
		focus:outline-none flex justify-center items-center;
	}

	.ButtonActive {
		@apply bg-gradient-to-t from-yellow-600 to-yellow-300  hover:from-yellow-300 hover:to-yellow-600
		rounded-xl p-1 
		border-solid border-1 border-genkiGray-light focus:border-genkiFocus
		hover:border-genkiBlue-dark 
		cursor-pointer
		focus:outline-none flex justify-center items-center;
	}

	.Wadah {
		@apply bg-gradient-to-t from-genkiBlue-light to-genkiBlue  hover:from-genkiBlue hover:to-genkiBlue-light
		rounded-xl 
		border-solid border-2 border-genkiGray-light focus:border-genkiBlue;
	}

	.WadahFix {
		@apply bg-gradient-to-t from-genkiBlue-light to-genkiBlue  
		border-solid border-2 border-genkiGray-light focus:border-genkiBlue;
	}

	.Modal {
		@apply flex justify-center bg-genkiGray-darkest bg-opacity-50  w-screen h-screen fixed top-0 left-0;
	}

	.Text {
		@apply rounded-xl pl-1 
		border-2 border-solid border-genkiGray-cl_01 focus:outline-none  focus:border-genkiFocus
		hover:border-genkiFocus w-full h-7 text-lg;
	}

	.Nav {
		@apply flex bg-genkiBlue-light py-1 w-full;
	}

	.NavUl {
		@apply hidden absolute  flex-col top-10 ml-1 bg-genkiBlue-light p-1 space-x-0 space-y-1 md:static md:flex md:flex-row md:bg-transparent md:space-x-3 md:space-y-0;
	}
}

.Table {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
}

.Table td,
.Table th {
	border: 1px solid #ddd;
	padding: 1px;
}

.Table tr {
	background-color: #c7b9b9;
}

.Table tr:nth-child(even) {
	background-color: white;
}

.Table tr:hover {
	background-color: #ddd;
}

.Table th {
	background-color: #1fb6ff;
	color: white;
}

.Table tr td {
	border: 1px solid #ddd;
}

.Table2 {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
}
.Table2 th {
	border: 1px solid #ddd;
	padding: 1px;
	background-color: #1fb6ff;
	color: white;
}
.Table2 td,
.Table2 th {
	border: 1px solid #ddd;
	padding: 1px;
}

.Table2 tr td {
	border: 1px solid #ddd;
}
