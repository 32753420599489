.imageRead {
	display: flex;
	border-radius: 6px;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.imageRead:hover {
	cursor: pointer;
	border: 3px solid #aaa;
	height: 155px;
	width: 95%;
}

.btnImageRead {
	border-radius: 6px;
	height: 15px;
	width: 40px;
	font-size: 10px;
	font-weight: bold;
	margin: auto;
	display: flex;
	justify-content: center;
	justify-items: center;
}

.btnImageRead:hover {
	cursor: pointer;
	background-color: rgb(125, 211, 233);
}
